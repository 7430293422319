* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
}

:root {
  --clr-main: gray;
  --clr-orange-btn: #b32eeb;
}

img {
  caret-color: transparent;
}

/* FOOTER */


.footer-wrapper {
  border-top: 1px solid black;
}

footer {
  background-color: #fff;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 135px;
  font-size: 24px;

}

.footer a {
  color: #000;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.footer svg {
  padding: 6px;
  border: 1px solid var(--clr-main);
  border-radius: 8px;
  font-size: 32px;
  cursor: pointer;
}

.footer a:hover {
  color: var(--clr-main);
}

.footer svg:hover {
  background-color: lightgray;
}

/* NAVBAR */

.logo {
  width: 50%;
}

.navbar {
  background-color: #fff;
  border-bottom: 1px solid #000;
}

.nav-link {
  font-size: 20px !important;
  color: #000 !important;
  margin-right: 10px;
  cursor: pointer;
  caret-color: transparent;
}

.nav-link:hover {
  color: var(--clr-main) !important;
}

.active {
  color: var(--clr-main) !important;
  border-radius: 8px;
  font-weight: 700;
}

.active:hover {
  color: gray !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* HOME - Introduction */

#home {
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}

.h2-home {
  font-size: 4em;
  margin-top: 50px;
}

.home-logo {
  text-align: center;
}

.home-logo img {
  width: 150px;
  height: auto;
  object-fit: cover;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.logo-carousel {
  width: 50%;
  aspect-ratio: 4/3;
  object-fit: contain;
}

.home-p {
  font-size: 1.5rem;
}

/* PROJECTS */

#projects {
  min-height: 90vh;
  background: #f9f9f9;
  padding: 16px 0 62px 0;
}

#projects h2 {
  color: black;
  margin-top: 70px;
  margin-bottom: 40px;
  font-size: 3.5em;
  font-weight: bold;
}

.projects-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project-div {
  cursor: pointer;
  margin: 20px;
  background-color: #fff;
  width: 90%;
}

.project-div a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.project-card {
  display: flex;
  gap: 2rem;
}

.project-card-text {
  padding: 1rem;
  font-size: 1.25rem;
  color: #000;
}

.project-card-text h4 {
  font-weight: bold;
}

.project-div img {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

/* CONTACT */
#contact {
  min-height: 90vh;
  padding: 60px 0px;
  background-color: #F9F9F9;
  background-size: 100% 100%;
  background-position: center;
}

.contact-row,
.text-contact {
  display: flex;
  align-items: center;
}

.text-contact p {
  font-size: 24px;
  width: 80%;
  color: #000;
}

#contact h2 {
  margin-bottom: 60px;
  text-align: center;
  font-weight: bold;
  font-size: 3.5em;
  align-self: flex-start;
  color: #000;
}

.form-contact label {
  margin-bottom: 5px;
  font-size: 24px;
}

.form-contact input,
.form-contact textarea {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 16px;
  padding-left: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.89);
}

.form-contact textarea {
  height: 100px;
}

.name-lastname-div {
  display: flex;
}

.name-lastname-div-inputs {
  width: 100%;
}

.form-contact textarea {
  resize: none;
}

.return-p-msg {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  color: #000;
}

.form-contact button {
  width: 166px;
  height: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  background-color: #000;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 24px;
  border: none;
  color: #fff;
}

.form-contact button:hover {
  background-color: var(--clr-main);
}

.return-msg-container p {
  text-align: center;
  padding-top: 150px;
}

/* ABOUT ME */

#about {
  min-height: 90vh;
  padding-top: 16px;
  padding-bottom: 60px;
}

#about h2 {
  font-size: 3.5em;
  margin-bottom: 32px;
  margin-top: 70px;
  font-weight: bold;
}

#about h4 {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: bold;
}

#about p {
  font-size: 20px;
  margin-bottom: 24px;
  width: 80%;
}

#about p::first-letter {
  margin-left: 16px;
}

#about li {
  font-size: 20px;
}

.sobre-mim-div {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.skills-card {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.cv-link {
  margin-top: 50px;
  width: 168px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border-radius: 24px;
  font-size: 24px;
}

.cv-link:hover {
  background-color: var(--clr-main);
}

/* SINGLE PROJECTS */

.portodoacu,
.nexa,
.nestle,
.loreal {
  min-height: 90vh;
  margin-top: 100px;
  padding: 60px 0px;
}

.portodoacu img,
.loreal img,
.nexa img,
.nestle img {
  width: 100%;
}

iframe {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
}

/* BANNER */

#banner {
  padding: 60px 0px;
  min-height: 100vh;
  background: url("/src/assets/imgs/banner.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.5);
}

#banner h2 {
  padding: 0 1rem;
  font-size: 3.5em;
  text-align: center;
  font-weight: bold;
  position: absolute;
  margin: 0 auto;
  z-index: 5;
  right: 0;
  left: 0;
  top: 40%;
  width: 50%;
}

/* ERROR PAGE */

.error-page {
  min-height: 90vh;
  margin-top: 100px;
}

/* MEDIA QUERY */

@media screen and (max-width: 1700px) {
  #banner h2 {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  #about p {
    width: 100%;
  }

  #banner {
    display: block;
  }

  #banner h2 {
    font-size: 3em;
    top: 40%;
  }

  .project-div img {
    width: 100%;
    height: 350px;
    object-fit: cover;
  }

  .project-card {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {

  #about,
  #home,
  #contact {
    padding-left: 20px;
    padding-right: 20px;
  }

  #banner h2 {
    font-size: 2em;
  }

  .h2-home {
    font-size: 4em;
  }

  .h3-home {
    font-size: 2em;
  }

  .home-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .text-contact p {
    font-size: 22px;
    width: 100%;
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 575px) {

  .nav-link,
  .logo {
    margin-left: 20px;
  }

  .home-p {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    justify-content: center;
    height: 160px;
  }

  .h2-home {
    font-size: 3em;
  }

  .h3-home {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 400px) {
  .home-p {
    font-size: 1rem;
  }

  .h2-home {
    font-size: 2.5em;
  }

  .logo-carousel {
    width: 65%;
  }
}

@media screen and (max-width: 350px) {
  .h2-home {
    font-size: 2em;
  }
}